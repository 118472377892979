import React, { Component } from 'react';


const encode = (data) => {
   return Object.keys(data)
       .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
       .join("&");
 }

class Contact extends Component {
   
   state = { contactName:      '', 
             contactEmail:     '', 
             contactSubject:   '', 
             contactMessage:   '' 
            } ;

   handleSubmit = e => {
      fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contactForm", ...this.state })
      })
      .then(() => alert("Message envoyé avec succès ☺️ . Merci "))
      .catch(error => alert(error));

      e.preventDefault();
   };

   // handleChange = e => this.setState({ [e.target.name]: e.target.value });

   handleChange = e => {
      const { name, value } = e.target
      this.setState({ [name]: value })
    }

  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;
    }



//  const { name_guest , object_guest , email_guest , message_guest } = this.state;

    return (
      <section id="contact">

         <div className="row section-head">

            <div className="two columns header-col">

               <h1><span> </span></h1>

            </div>

            <div className="ten columns">

                  <p className="lead">{message}</p>

            </div>

         </div>

         <div className="row">
            <div className="eight columns">

               <form name = "contactForm" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit} >
					<fieldset>

                  <div>
						   <label htmlFor="contactName">Nom <span className="required">*</span></label>
						   <input type="text" value={this.state.contactName} placeholder='Nom' size="35" id="contactName" name="contactName" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactEmail">Email <span className="required">*</span></label>
						   <input type="email"  value={this.state.contactEmail} placeholder='yyy@exemple.com' size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactSubject">Objet</label>
						   <input type="text"  value={this.state.contactSubject} placeholder='encouragements' size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange}/>
                  </div>

                  <div>
                     <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                     <textarea  value={this.state.contactMessage} placeholder='Votre message ici' cols="50" rows="15"   id="contactMessage" name="contactMessage" onChange={this.handleChange}></textarea>
                  </div>
                  <input type="hidden" name="form-name" value="contactForm" /> 
                  <div>
                     <button className="submit">Envoyer</button>
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span>
                  </div>
					</fieldset>
				   </form>

           <div id="message-warning"> Erreur envoi message</div>
				   <div id="message-success">
                  <i className="fa fa-check"></i>Merci de votre message. Je vous répondrai dans les meilleurs délais !<br />
				   </div>
           </div>


{/*             <aside className="four columns footer-widgets">
               <div className="widget widget_contact">

					   <h4>Address and Phone</h4>
					   <p className="address">
						   {name}<br />
						   {street} <br />
						   {city}, {state} {zip}<br />
						   <span>{phone}</span>
					   </p>
				   </div>

               <div className="widget widget_tweets">
                  <h4 className="widget-title">Latest Tweets</h4>
                  <ul id="twitter">
                     <li>
                        <span>
                        This is Photoshop's version  of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet.
                        Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum
                        <a href="#">http://t.co/CGIrdxIlI3</a>
                        </span>
                        <b><a href="#">2 Days Ago</a></b>
                     </li>
                     <li>
                        <span>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore veritatis et quasi
                        <a href="#">http://t.co/CGIrdxIlI3</a>
                        </span>
                        <b><a href="#">3 Days Ago</a></b>
                     </li>
                  </ul>
		         </div>
            </aside> */}
      </div>
   </section>
    );
  }
}

export default Contact;
